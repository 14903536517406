import request from '@/service/lib/request';


export function getList () {
  return request({
    url: '/banner/banners',
    method: 'get'
  });
}

export function deleteModel (id) {
  return request({
    url: '/banner/banner/' + id,
    method: 'delete'
  });
}
export function addModel (data) {
  return request({
    url: '/banner/banner',
    method: 'post',
    data
  });
}
export function sortModel (data) {
  return request({
    url: '/banner/banner',
    method: 'put',
    data
  });
}